export default {
    "box-name": "en-style",
    lang: "vn",
  了解指数差价合约: "Tìm hiểu về Index CFDs",
    "public-btn-text1": "Xem phiên giao dịch",
    "public-btn-text2": "Xem spread và toàn bộ sản phẩm",
    screen0: {
        "title": "Chỉ có thời gian mới giúp bạn hiểu rõ hơn về tôi",
        "desc": "Hơn 60 sản phẩm tài chính với báo giá có tính cạnh tranh cao sẽ đưa bạn vào những thị trường thanh khoản phổ biến nhất thế giới, đồng thời kiểm soát các cơ hội giao dịch tốt nhất 24/5.",
        "btn": "tìm hiểu thêm",
    },
    screen1: {
        "link1": "Giao dịch ngoại hối",
        "link2": "Hàng hóa",
        "link3": "Index CFDs",
        "link4": "Stock CFDs",
    },
    screen2: {
        "title": "Giao dịch ngoại hối",
        "text1": "Đòn bẩy cao nhất lên tới 500:1",
        "text2": "Tốc độ khớp lệnh trung bình 20ms",
        "imgUrl": "trade-01",
        "title2": "Giao dịch 36 cặp tiền tệ<br>chỉ với 0 pips",
        "desc2": "Báo cáo tháng 9 năm 2019 của Ngân hàng Thanh toán Quốc tế (BIS) cho thấy khối lượng giao dịch trên thị trường phái sinh toàn cầu đã tăng lên mức cao kỷ lục, với khối lượng giao dịch trung bình hàng ngày là 6,6 nghìn tỷ USD.",
    },
    screen3: {
        "title": "Hàng hóa",
        "text1": "Đòn bẩy linh hoạt",
        "text2": "Spread cạnh tranh",
        "text3": "Giữ vị thế linh hoạt",
        "title2": "Hàng hoá là lựa chọn lý tưởng cho bạn nếu muốn đa dạng hoá danh mục đầu tư",
        "desc2": "Kim loại quý, dầu thô, khí đốt tự nhiên, v.v.",
    },
    screen4: {
        "title": "Index CFDs",
        "text1": "Giá cả linh hoạt",
        "text2": "Tốc độ khớp lệnh trung bình 20ms",
        "text3": "Đòn bẩy cao nhất lên tới 100:1",
        "text4": "Không có hoa hồng ẩn và phí giao dịch",
        "title2": "Chọn các chỉ số hàng đầu cho bạn và tích cực khám phá những lĩnh vực năng động và có giá trị nhất của nền kinh tế toàn cầu",
    },
    screen5: {
        "title": "Stock CFDs",
        "text1": "Chi phí giao dịch cạnh tranh",
        "text2": "Chọn lọc 51 cổ phiếu phổ biến của Mỹ",
        "text3": "Hỗ trợ thanh khoản đặc biệt",
        "text4": "Hỗ trợ giao dịch hai chiều",
        "title2": "Stock CFDs giúp bạn dễ dàng thêm các công ty hàng đầu thế giới vào danh sách đầu tư của mình",
    },
}