export default {
	"contactUs": {
		"title": "最も厳格な金融規制と高い資金安全性を享受し、今すぐ口座開設",
		"title1": "すべてのトレーダーに、誠実な対応を。",
		"code": "認証コード",
	},
	"dividend": {
		"title": "CFD配当金",
		"title1": "CFD配当金を参照する:",
		"desc": "*注意事項。CFD配当金の受け取りは、MT4口座の残高に「Dividend」と記載された預金または引き出しの形で反映されます。",
		"btn": "投資をはじめる"
	},
	"notice": "「EBankc」という会社が我々、EBC グループをなりすまし、あるオンライン仮想通貨サービスを提供していることが判明されました。<br/><br/>EBC グループの公式ウェブサイトは www.ebc.com と www.ebcfin.com であり、当社が提供しているサービスおよび各商品の詳細が記載されています。仮想通貨は該当しません。<br/><br/>また、EBC Financial Group (UK) Ltd の公式ウェブサイト www.ebcfin.co.uk は 1 つだけであり、当社が提供しているサービスおよび各商品の詳細が記載されています。 仮想通貨は該当しません。<br/><br/>すべての EBC グループ企業は、事業を行っている法域の規則および規制の下で事業を行っております。第三者による無許可のプロジェクトが弊社のブラン名を使用するのを阻止するため、必要なあらゆる保護措置を講じます。",
	"contact": {
		"title": "すぐに旅を始めましょう",
		"pageTitle": "ヘルプ・お問い合わせ",
		"pageEnTitle": "Inquiry",
		"name": "お名前",
		"sexList": [{
			"label": "男性",
			"value": "sir"
		}, {
			"label": "女性",
			"value": "lady"
		}],
		"email": "電話番号/メール",
		"btn": "お問い合わせ",
		"tips1": "あなたの名前を入力してください!",
		"tips2": "連絡先を入力してください!",
		"tips3": "メッセージ成功!",
		"tips4": "正しい検証コードを入力してください!"
	},
	"column1": {
		"placeholder": "「サイト内検索」",
		"login": "ログイン",
		"langText": "EBCウェブサイト地域/言語",
		"langList": [{
			"text": "简体",
			"value": "zh-CN"
		}, {
			"text": "繁体",
			"value": "zh_TW"
		}, {
			"text": "English",
			"value": "en_US"
		}, {
			"text": "한국어",
			"value": "ko_KR"
		}, {
			"text": "ภาษาไทย",
			"value": "th_TH"
		}, {
			"text": "ئۇيغۇر تىلى",
			"value": "weiwuer"
		}]
	},
	"linkList": [{
		"title": "取引関連",
		"list": [{
				"label": "取引口座",
				"link": "/TradingAccount"
			},{
				"label": "取引商品",
				"link": "/tradingproduct"
			},{
				"label": "レバレッジ・証拠金",
				"link": "/leverage-margin"
			},
			{
				"label": "入出金",
				"link": "/funding"
			},
			{
				"label": "CFD配当金",
				"link": "/dividend"
			}
		]
	},{
		"title": "取引ツール",
		"list": [{
				"label": "MT4 ダウンロード",
				"link": "/MT4"
			},{
				"label": "MT5 ダウンロード",
				"link": "/MT5"
			},{
				"label": "MT4 取引ツール",
				"link": "/tools"
			}, {
				"label": "PAMM",
				"link": "/PAMM"
			}, {
				"label": "市場分析",
				"link": "/market-analysis"
			}
		]
	},{
		"title": "EBC研究所",
		"list": [{
				"label": "学習センター",
				"link": "/LearningCenter"
			}, {
				"label": "データセンター",
				"link": "/lab"
			}
		]
	}, {
		"title": "キャンペーン",
		"list": [
		// 	{
		// 	"label": "トレーディングコンテスト",
		// 	"link": "/win"
		// },
		// {
		// 	"label": "最高の取引環境キャンペーン",
		// 	"link": "/no1"
		// },
		{
			"label": "ハッピーパートナープログラム",
			"link": "/cfa"
		}]
	}, {
		"title": "パートナー",
		"list": [{
			"label": "パートナーシップ",
			"link": "/affiliate-programmes"
		},{
			"label": "流動性サービス",
			"link": "/service"
		},{
			"label": "フィンテック",
			"link": "/technology"
		}]
	}, {
		"title": "EBCについて",
		"list": [{
			"label": "会社情報",
			"link": "/about-us"
		},  {
			"label": "法的文書",
			"link": "/LegalDocumen"
		}, {
			"label": "サステナビリティ",
			"link": "/ESG"
		}, {
			"label": "よくある質問",
			"link": "https://support.ebchelp.com/hc/ja-jp"
		},{
			"label": "公式チャンネルの認証",
			"link": "/official-verification"
		}]
	}],
	"headlist": [{
		"text": "ホーム",
		"url": "/"
	}, {
		"text": "取引",
		"menu": [{
			"text": "取引商品",
			"url": "/tradingproduct"
		}, {
			"text": "取引アカウント",
			"url": "/TradingAccount"
		}, {
			"text": "取引条件",
			"menu": [{
				"text": "入手金",
				"url": "/funding"
			}, {
				"text": "レバレッジ＆証拠金",
				"url": "/leverage-margin"
			}]
		}, {
			"text": "取引プラットフォーム",
			"menu": [{
				"text": "MT4",
				"url": "/MT4"
			}, {
				"text": "MAM",
				"url": "/MAM"
			}]
		}, {
			"text": "利益配当",
			"url": "/dividend"
		}]
	}, {
		"text": "研究機関と取引ツール",
		"menu": [{
			"text": "研究センター",
			"url": "/LearningCenter"
		}, {
			"text": "データセンター",
			"url": "/lab"
		}]
	}, {
		"text": "機構協力",
		"menu": [{
			"text": "テクノロジー",
			"url": "/technology"
		}, {
			"text": "サービス",
			"url": "/service"
		}, {
			"text": "パートナー",
			"url": "/partner"
		}]
	}, {
		"text": "EBCについて",
		"menu": [{
			"text": "Why EBC",
			"url": "/about-us"
		}, {
			"text": "FAQ",
			"url": "https://support.ebchelp.com/hc/ja-jp"
		}]
	}, {
		"text": "取引コンテスト",
		"url": "/win"
	}, {
		"text": "無料口座開設",
		"url": "https://client.ebccrm.com/signup"
	}],
	"login": "ログイン",
	"zhuce": "口座開設",
	"gendan": "荷為替コミュニティ",
	"pdfLang": "jp",
	"bannerLang":"ja",
	"language": "日本語",
	"className": "ja-box",
	"text": `
	EBC Financial Groupは下記の法人から成り立っています:<br />
        EBC Financial Group (SVG) LLC
        は、セントビンセントおよびグレナディーン諸島金融サービス庁によって認可および規制されています。<br />
        登録番号： 353 LLC 2020<br />

        その他の関連団体<br />
        EBC Financial Group (UK) Limited
        は英国金融局によって認可および規制されています。登録番号： 927552<br />
        ホームページ:
        <a
          style="color: #fff"
          target="_blank"
          href="https://www.ebcfin.co.uk"
          rel="nofollow"
          >www.ebcfin.co.uk</a
        ><br />
        EBC Financial Group (Australia) Pty Ltd (会社番号: 619 073 237)
        は、オーストラリア証券投資委員会によって認可および規制されています。<br />
        登録番号：500991<br />
        EBC Financial Group (Australia) Pty Ltd は EBC Financial Group (SVG)
        の関連会社であり、独立して運営されています。
        当ウェブサイトに掲載されている取引商品及びサービスは当社オーストラリア法人によって提供されているものではなく、オーストラリア法人に対していかなる賠償請求もできません。<br />
        EBC Financial Group (Cayman) Limited
        は、ケイマン諸島金融管理局によって認可および規制されています。<br />
        登録番号: 2038223<br />
        Webサイト：
        <a
          style="color: #fff"
          target="_blank"
          href="https://www.ebcgroup.ky"
          rel="nofollow"
          >www.ebcgroup.ky</a
        ><br />
        会社住所: The Leadenhall Building, 122 Leadenhall Street, London, United
        Kingdom, EC3V 4AB<br />
        メールアドレス: cs@ebc.com<br />
        電話番号: +44 20 3376 9662<br />

        免責事項: EBC Financial
        Groupはいかなる仮想通貨取引または仮想通貨CFD取引サービスも提供いたしません。当社のサービスは従来の取引商品のみを取り扱っております。したがって当社の名称を用いた仮想通貨に関連したサービスは当社とは一切関係がありませんのでご注意ください。<br />
        過去の実績は将来の実績ではありません。本サイトに掲載されているコンテンツは情報提供のみを目的としており、投資助言を目的としたものではなく、いかなる投資判断に使用されるべきではありません。<br />

        地域制限:
        EBCは、以下の特定の地域の市民および居住者に対し、いかなるサービスも提供しません:
        アフガニスタン、ベラルーシ、ミャンマー、カナダ、中央アフリカ共和国、コンゴ、キューバ、コンゴ民主共和国、エリトリア、ハイチ、イラン、イラク、レバノン、リビア、マレーシア、マリ、北朝鮮（朝鮮民主主義人民共和国）、ロシア、ソマリア、スーダン、南スーダン、シリア、ウクライナ（クリミア、ドネツク、ルハンシク地域を含む）、米国、ベネズエラ、イエメン。
        詳細については、「よくある質問」をご覧ください。<br />

        コンプライアンスについて:
        当社のサイトは世界中からアクセスでき、いかなる組織に限定されるものではありません。
        お客様の権利と義務は、お客様の管轄区域に基づき、決められています。
        現地の法律や規制により、サイトに掲載されているコンテンツへのアクセス、ダウンロード、配布、共有、または使用が禁止または制限される場合があります。<br />

        リスク警告：差金決済取引（CFD）は複雑な金融商品であり、レバレッジが高いため、すぐに資金を失う可能性があるため、リスクが高いです。証拠金取引は高レベルのリスクを伴い、すべての投資家に適しているわけではありません。外国為替およびCFD取引を決定する前に、取引目標、経験レベル、リスク許容度を慎重に検討する必要があります。初期取引資本の一部または全部を失う可能性があります。投資を決定する前に、独立したアドバイスを受け、関連するリスクを完全に理解することをお勧めします。取引を開始する前に、関連するリスク開示文を注意深くお読みください。<br />
      
	`,
	"waptext":`
	
	EBC Financial Groupは下記の法人から成り立っています:<br />
        EBC Financial Group (SVG) LLC
        は、セントビンセントおよびグレナディーン諸島金融サービス庁によって認可および規制されています。<br />
        登録番号： 353 LLC 2020<br />

        その他の関連団体<br />
        EBC Financial Group (UK) Limited
        は英国金融局によって認可および規制されています。登録番号： 927552<br />
        ホームページ:
        <a
          style="color: #fff"
          target="_blank"
          href="https://www.ebcfin.co.uk"
          rel="nofollow"
          >www.ebcfin.co.uk</a
        ><br />
        EBC Financial Group (Australia) Pty Ltd (会社番号: 619 073 237)
        は、オーストラリア証券投資委員会によって認可および規制されています。<br />
        登録番号：500991<br />
        EBC Financial Group (Australia) Pty Ltd は EBC Financial Group (SVG)
        の関連会社であり、独立して運営されています。
        当ウェブサイトに掲載されている取引商品及びサービスは当社オーストラリア法人によって提供されているものではなく、オーストラリア法人に対していかなる賠償請求もできません。<br />
        EBC Financial Group (Cayman) Limited
        は、ケイマン諸島金融管理局によって認可および規制されています。<br />
        登録番号: 2038223<br />
        Webサイト：
        <a
          style="color: #fff"
          target="_blank"
          href="https://www.ebcgroup.ky"
          rel="nofollow"
          >www.ebcgroup.ky</a
        ><br />
        会社住所: The Leadenhall Building, 122 Leadenhall Street, London, United
        Kingdom, EC3V 4AB<br />
        メールアドレス: cs@ebc.com<br />
        電話番号: +44 20 3376 9662<br />

        免責事項: EBC Financial
        Groupはいかなる仮想通貨取引または仮想通貨CFD取引サービスも提供いたしません。当社のサービスは従来の取引商品のみを取り扱っております。したがって当社の名称を用いた仮想通貨に関連したサービスは当社とは一切関係がありませんのでご注意ください。<br />
        過去の実績は将来の実績ではありません。本サイトに掲載されているコンテンツは情報提供のみを目的としており、投資助言を目的としたものではなく、いかなる投資判断に使用されるべきではありません。<br />

        地域制限:
        EBCは、以下の特定の地域の市民および居住者に対し、いかなるサービスも提供しません:
        アフガニスタン、ベラルーシ、ミャンマー、カナダ、中央アフリカ共和国、コンゴ、キューバ、コンゴ民主共和国、エリトリア、ハイチ、イラン、イラク、レバノン、リビア、マレーシア、マリ、北朝鮮（朝鮮民主主義人民共和国）、ロシア、ソマリア、スーダン、南スーダン、シリア、ウクライナ（クリミア、ドネツク、ルハンシク地域を含む）、米国、ベネズエラ、イエメン。
        詳細については、「よくある質問」をご覧ください。<br />

        コンプライアンスについて:
        当社のサイトは世界中からアクセスでき、いかなる組織に限定されるものではありません。
        お客様の権利と義務は、お客様の管轄区域に基づき、決められています。
        現地の法律や規制により、サイトに掲載されているコンテンツへのアクセス、ダウンロード、配布、共有、または使用が禁止または制限される場合があります。<br />

        リスク警告：差金決済取引（CFD）は複雑な金融商品であり、レバレッジが高いため、すぐに資金を失う可能性があるため、リスクが高いです。証拠金取引は高レベルのリスクを伴い、すべての投資家に適しているわけではありません。外国為替およびCFD取引を決定する前に、取引目標、経験レベル、リスク許容度を慎重に検討する必要があります。初期取引資本の一部または全部を失う可能性があります。投資を決定する前に、独立したアドバイスを受け、関連するリスクを完全に理解することをお勧めします。取引を開始する前に、関連するリスク開示文を注意深くお読みください。<br />
      
	`,
}

