export default {
  "box-name": "",
  
  lang: "ja",
  了解指数差价合约: "株価指数取引の詳細",
  "public-btn-text1": "取引時間",
  "public-btn-text2": "取引銘柄とスプレッド一覧はこちら",
  screen0: {
      "title": "取引銘柄",
      "desc": "60以上の取引銘柄と魅力的な低スプレッド設定とともに、世界で最も人気で流動性の高い市場に24時間アクセスできる、最高の取引環境をご案内します。",
      "btn": "もっと詳しく知る",
  },
  screen1: {
      "link1": "FX取引",
      "link2": "コモディティ",
      "link3": "株価指数CFD",
      "link4": "個別株CFD",
  },
  screen2: {
      "title": "FX取引",
      "text1": "レバレッジ最大500倍",
      "text2": "平均約定速度20ms",
      "imgUrl": "trade-01",
      "title2": "最低 0 pipsから36種類の通貨ペアを取引可能",
      "desc2": "<span style=\"color: #000;font-weight: bold;\">FX取引は世界最大の金融取引市場</span><br>国際決済銀行（BIS）が2022年4月に発表したレポートによると、世界のFX市場の取引量は過去最高水準に跳ね上がり、1日の平均取引量は7.5兆ドルに達しました。",
  },
  screen3: {
      "title": "コモディティ",
      "text1": "多様なレバレッジ",
      "text2": "競争力のある低スプレッド",
      "text3": "柔軟な取引方式",
      "title2": "コモディティ取引をすることで、投資資産をより多様に",
      "desc2": "貴金属、原油、天然ガス等",
  },
  screen4: {
      "title": "株価指数CFD",
      "text1": "柔軟な価格設定",
      "text2": "平均約定速度20ms",
      "text3": "レバレッジ最大100倍",
      "text4": "透明な取引コスト",
      "title2": "世界中で最も人気で価値のある、米国・日本・欧州等の株価指数CFDを厳選",
  },
  screen5: {
      "title": "個別株CFD",
      "text1": "業界最低水準の取引コスト  ",
      "text2": "正在检索数据。请等待数秒，再尝试剪切或复制。",
      "text3": "他社より魅力的な流動性",
      "text4": "両建てが可能",
      "title2": "世界の有名企業の株式をより低コストで投資可能",
  },
}