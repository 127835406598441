export default {
  "box-name": "kr-style",
  lang: "ko",
  了解指数差价合约: "배우다현물 지수 CFD",
  "public-btn-text1": "거래시간 보기",
  "public-btn-text2": "스프레드 및 모든 제품 보기",
  screen0: {
    title: "오직 시간만이 자신을 더<br> 잘 알 수 있게 해줍니다",
    desc: "60개가 넘는 금융상품과 경쟁력 있는 기구의 가격 책정은 전 세계에서 가장 유동성이 높은 시장의 진입과 24/5의 최적의 거래 기회를 제공합니다. ",
    btn: "더 알아보기",
  },
  screen1: {
    link1: "파생상품 거래",
    link2: "원자재 상품",
    link3: "지수 CFDs",
    link4: "주식 CFD",
  },
  screen2: {
    title: "파생상품 거래",
    text1: "500:1의 레버리지 비율 ",
    text2: "평균 실행 속도20ms",
    imgUrl: "trade-01",
    title2: "최저 0핍으로 36종류의 통화 쌍을 거래할 수 있습니다.",
    desc2:
      "세계 최대 금융 거래 시장인 BIS가 2019년 9월에 발표한 보고서에 따르면 글로벌 파생상품 시장의 거래량이 역대 최고 수준으로 뛰어올랐으며, 일일 평균 거래량이 6조 6천억 달러에 도달했습니다. ",
  },
  screen3: {
    title: "생성물",
    text1: "유연한 레버리지",
    text2: "매우 경쟁력 있는 스프레드",
    text3: "유연한 오픈 포지션 거래",
    title2:
      "투자 포트폴리오를 다양화하고 싶다면, 원자재 상품은 이상적인 선택입니다",
    desc2: "귀금속, 원유, 천연가스 등",
  },
  screen4: {
    title: "현물 지수 CFD",
    text1: "유연한 가격 책정",
    text2: "평균 실행 속도 20ms",
    text3: "최대 100:1의 레버리지 비율",
    text4: "히든 커미션 및 거래 비용 없음",
    title2:
      "글로벌 주요 지수를 선정하여 세계 경제에서 가장 가치 있고 활발한 분야를 적극적으로 탐색합니다.  ",
  },
  screen5: {
    title: "주식 CFD",
    text1: "경쟁력 있는 거래 비용",
    text2: "51개의 인기 미국 주식을 선택",
    text3: "우수한 유동성 지원",
    text4: "양방향 거래 기회",
    title2:
      "주식 CFD, 세계 최고의 기업을 투자 포트폴리오에 손쉽게 추가할 수 있습니다 ",
  },
};
