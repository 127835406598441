export default {
    "box-class-name": "cn-box",
    "lang": "zt",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTrader 5 (MT5) 是新一代多元資產交易平台，整合了更先進的交易功能和分析工具，滿足全球交易者的多樣化需求。",
    "提供市场深度信息": "提供市場深度資訊，讓交易者可以更好地了解市場的流動性。",
    "支持6种挂单交易类型": "支援6種掛單交易類型，靈活滿足各種交易需求。",
    "内置经济日历": "內建經濟日曆，即時更新全球重要經濟事件。",
    "立即注册": "立即註冊",
    "MetaTrader 5下载": "MetaTrader 5下載",
    "MT5移动版": "MT5 行動版",
    "随时随地进行交易": "隨時隨地進行交易，掌握市場動態，輕鬆管理帳戶。",
    "下载Android版": "下載Android版",
    "下载IOS版": "下載iOS版",
    "下载iPad版": "下載iPad版",
    "MT5桌面版": "MT5桌面版",
    "全面的交易工具和高级图表分析": "全面的交易工具和進階圖表分析，滿足專業交易者的高階需求。",
    "下载Windows版": "下載Windows版",
    "下载MacOS版": "下載MacOS版",
    "MT5网页版": "MT5網頁版",
    "无需下载": "無需下載，極速訪問全球市場，輕鬆實現全方位的線上交易管理。",
    "打开网页版": "開啟網頁版",
    "MT4与MT5的区别": "MT4與MT5的差別",
    "MT4和MT5均由MetaQuotes开发": "MT4和MT5均由MetaQuotes開發，但MT5並非MT4的簡單升級版，兩者在功能和適用範圍上有著明顯差異。",
    "项目": "項目",
    "发布时间": "發佈時間",
    "2005年": "2005年",
    "2010年": "2010年",
    "交易市场": "交易市場",
    "主要是外汇": "主要是外匯",
    "包括股票": "包括股票、期貨、外匯等",
    "挂单类型": "掛單類型",
    "4种": "4種",
    "6种": "6種",
    "时间框架": "時間框架",
    "9种": "9種",
    "21种": "21種",
    "技术指标": "技術指標",
    "30个": "30個",
    "38个": "38個",
    "市场深度":"市場深度",
    "不支持":"不支援",
    "支持":"支援",
    "财经日历":"財經日曆",
    "内置":"內建",
    "操作界面":"操作介面",
    "更直观简单":"更直覺簡單",
    "更加现代化灵活":"更現代化靈活"
}