export default {
    "box-class-name": "en-box",
    "lang": "vn",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTrader 5 (MT5) là nền tảng giao dịch đa tài sản thế hệ mới, tích hợp các chức năng giao dịch và công cụ phân tích tiên tiến hơn, đáp ứng nhu cầu đa dạng của các nhà giao dịch trên toàn cầu.",
    "提供市场深度信息": "Cung cấp thông tin có độ sâu, giúp nhà giao dịch hiểu rõ hơn về tính thanh khoản của thị trường.",
    "支持6种挂单交易类型": "Hỗ trợ 6 loại lệnh chờ, đáp ứng linh hoạt các nhu cầu giao dịch khác nhau.",
    "内置经济日历": "Tích hợp lịch kinh tế, cập nhật theo thời gian thực các sự kiện kinh tế quan trọng trên toàn cầu.",
    "立即注册": "Đăng ký ngay",
    "MetaTrader 5下载": "Tải xuống MetaTrader 5",
    "MT5移动版": "MT5 Mobile",
    "随时随地进行交易": "Giao dịch mọi lúc mọi nơi, nắm bắt động thái thị trường, dễ dàng quản lý tài khoản.",
    "下载Android版": "Tải xuống bản cho Android",
    "下载IOS版": "Tải xuống bản cho iOS",
    "下载iPad版": "Tải xuống bản cho iPad",
    "MT5桌面版": "MT5 Desktop",
    "全面的交易工具和高级图表分析": "Công cụ giao dịch toàn diện và phân tích biểu đồ nâng cao, đáp ứng nhu cầu cao cấp của các nhà giao dịch chuyên nghiệp.",
    "下载Windows版": "Tải xuống bản Windows",
    "下载MacOS版": "Tải xuống bản MacOS",
    "MT5网页版": "MT5 WebTrader",
    "无需下载": "Không cần tải xuống, truy cập nhanh chóng vào thị trường toàn cầu, dễ dàng thực hiện quản lý giao dịch trực tuyến toàn diện.",
    "打开网页版": "Khởi chạy Webtrader",
    "MT4与MT5的区别": "Sự khác biệt giữa MT4 và MT5",
    "MT4和MT5均由MetaQuotes开发": "MT4 và MT5 đều do MetaQuotes phát triển, nhưng MT5 không chỉ đơn thuần là phiên bản nâng cấp của MT4; hai nền tảng này có sự khác biệt rõ rệt về chức năng và phạm vi ứng dụng.",
    "项目": "Mục",
    "发布时间": "Thời gian phát hành",
    "2005年": "Năm 2005",
    "2010年": "Năm 2010",
    "交易市场": "Thị trường giao dịch",
    "主要是外汇": "Chủ yếu là forex",
    "包括股票": "Bao gồm chứng khoán, hợp đồng tương lai, ngoại hối v.v",
    "挂单类型": "Các loại lệnh chờ",
    "4种": "4 loại",
    "6种": "6 loại",
    "时间框架": "Khung thời gian",
    "9种": "9 loại",
    "21种": "21 loại",
    "技术指标": "Chỉ báo",
    "30个": "30 loại",
    "38个": "38 loại",
    "市场深度":"Độ sâu thị trường",
    "不支持":"Không hỗ trợ",
    "支持":"Hỗ Trợ",
    "财经日历":"Lịch tài chính",
    "内置":"Tích hợp sẵn",
    "操作界面":"Giao diện thao tác",
    "更直观简单":"Đơn giản hơn",
    "更加现代化灵活":"Linh hoạt hơn"
}