export default {
	"headlist": [{
		"text": "첫 장",
		"url": "/"
	}, {
		"text": "거래",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "계정 및 조건",
			"list": [ {
				"text": "거래 계정",
				"url": "/TradingAccount"
			}, {
				"text": "거래 제품",
				"url": "/tradingproduct"
			}, {
				"text": "레버리지 마진",
				"url": "/leverage-margin"
			},{
				"text": "입출금",
				"url": "/funding"
			}, {
				"text": "피제수",
				"url": "/dividend"
			}]
		},  {
			"tit": "트레이딩 도구",
			"list": [{
				"text": "MT4 다운로드",
				"url": "/MT4"
			},{
				"text": "MT5 다운로드",
				"url": "/MT5"
			},{
				"text": "MT4 거래 도구",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			},{
				"text": "마케팅 분석",
				"url": "/market-analysis"
				
			}]
		}]
	}, {
		"text": "EBC 연구원",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "교육 중심",
				"url": "/LearningCenter"
			}, {
				"text": "데이터 중심",
				"url": "/lab"
			},]
		}]
	},{
		"text": "최신 이벤트",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "트레이딩 대회",
				"url": "/win"
			},{
				"text": "거래 급여 프로그램",
				"url": "/pay"
			}
			// {
			// 	"text": "최강의 거래 환경을 시작해보세요",
			// 	"url": "/no1"
			// }
		]
		}]
	}, {
		"text": "EBC 소개",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "EBC 소개",
			"list": [{
				"text": "WHY EBC",
				"url": "/about-us"
			},{
				"text": "Sustainability & Impact",
				"url": "/ESG"
			}, {
				"text": "FAQ",
				"url": "/FAQ"
			}]
		},{
			"tit": "협력 모드",
			"list": [{
				"text": "임팩트 얼라이언스",
				"url": "/affiliate-programmes"
			},{
				"text": "기관 서비스",
				"url": "/service"
			},{
				"text": "기반 기술",
				"url": "/technology"
			},
			]
		}]
	}],
	"FCA": "FCA 계정",
	"login": "계좌 개설",
	"zhuce": "로그인",
	"from0tohero_text": "다큐멘터리 커뮤니티",
	"from0tohero_url": "/from0tohero",
	"faq_url": "/FAQ",
}

