export default {
    "box-class-name": "en-box",
    "lang": "vi",
    "MT4是最受欢迎的交易平台之一": "MetaTrader 4 (MT4) là một trong những nền tảng giao dịch phổ biến nhất thế giới hiện nay. Với công nghệ tiên tiến, dễ sử dụng và mức độ an toàn cao, nền tảng này đã được các nhà giao dịch trên toàn cầu công nhận rộng rãi.",
	"内核稳定流畅": "Cung cấp chức năng phân tích biểu đồ phong phú, giúp nhà giao dịch xây dựng chiến lược giao dịch chính xác.",
	"支持EA交易": "Hỗ trợ hệ thống giao dịch thông minh EA, giúp thực hiện các giao dịch tự động.",
	"活跃用户社区": "Tích hợp nhiều chỉ báo kỹ thuật và công cụ phân tích, đáp ứng nhu cầu của các nhà giao dịch khác nhau.",
	"立即注册": "Đăng ký ngay",
    "MetaTrader 4下载": "Tải xuống MetaTrader 4",
    "MT4移动版": "MT4 Mobile",
    "随时随地进行交易": "Giao dịch mọi lúc mọi nơi, nắm bắt động thái thị trường, dễ dàng quản lý tài khoản.",
    "下载Android版": "Tải xuống bản cho Android",
    "下载IOS版": "Tải xuống bản cho iOS",
    "下载iPad版": "Tải xuống bản cho iPad",
    "MT4桌面版": "MT4 Desktop",
    "全面的交易工具和高级图表分析": "Công cụ giao dịch toàn diện và phân tích biểu đồ nâng cao, đáp ứng nhu cầu cao cấp của các nhà giao dịch chuyên nghiệp.",
    "下载Windows版": "Tải xuống bản Windows",
    "下载MacOS版": "Tải xuống bản MacOS",
    "MT4网页版": "MT4 WebTrader",
    "无需下载": "Không cần tải xuống, truy cập nhanh chóng vào thị trường toàn cầu, dễ dàng thực hiện quản lý giao dịch trực tuyến toàn diện.",
    "打开网页版": "Khởi chạy Webtrader"
}